<template>
    <div class="table">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-lx-cascades"></i>wifi管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-input v-model="select_word" placeholder="wifi名称" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="search" @click="search">搜索</el-button>
                <el-button type="primary" style="float: right" @click="handleEdit(undefined, undefined, 1)">添加</el-button>
            </div>
              <el-table :data="data" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="ID" width="70" align="center"></el-table-column>
                <el-table-column prop="title" align="center" label="Wifi名称" :show-overflow-tooltip='true'>
                </el-table-column>
                <el-table-column prop="password" align="center" label="密码">
                </el-table-column>
                <el-table-column prop="content" align="center" label="账户描述">
                </el-table-column>
                 <el-table-column prop="longitude" align="center" label="商城经度">
                </el-table-column>
                 <el-table-column prop="dimensionality" align="center" label="商城纬度">
                </el-table-column>
                <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row, 2)">编辑</el-button>
                <el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
                </el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-loading="loading" :visible.sync="editVisible" width="70%">
                 <el-form ref="form" :rules="rules" :model="form" label-width="130px">
                <el-form-item label="wifi名称" prop="title">
                    <el-input v-model="form.title" style="width:400px" placeholder="请输入wifi名称"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="form.password" style="width:400px" placeholder="请输入满足密码"></el-input>
                </el-form-item>
                <el-form-item label="wifi描述" prop="content">
                    <el-input v-model="form.content" style="width:400px" placeholder="请输入wifi描述"></el-input>
                </el-form-item>
                <el-form-item label="商城经度" prop="longitude">
                    <el-input v-model="form.longitude" style="width:400px" placeholder="商城经度"></el-input>
                </el-form-item>
				<el-form-item label="商城纬度" prop="dimensionality">
				    <el-input v-model="form.dimensionality" style="width:400px" placeholder="商城经度"></el-input>
				</el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveEdit('form')">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 删除提示框 -->
        <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
            <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delVisible = false">取 消</el-button>
                <el-button type="primary" @click="deleteRow">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
 
    export default {
        name: 'basetable',
        data() {
            return {
                url: './vuetable.json',
                tableData: [],
                cur_page: 1,  //当前页
                number: 10,  //每页显示条数
                sumPage:10,  //总页数
                multipleSelection: [],
                select_cate: '',
                select_word: '',
                del_list: [],
                is_search: false,
                editVisible: false,
                delVisible: false,
                form: {
                    id: '',
                    title: '',
                    password: '',
                    content: '',
                    longitude: '',
                    dimensionality: '',
                },
                idx: -1,
                dialogVisible: false,
                AddOrSave:'',  //1表示添加，2表示更新
                  rules: {
                    title: [
                        { required: true, message: '输入wifi名称', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '输入wifi密码', trigger: 'blur' }
                    ],
                    content: [
                        { required: true, message: '输入WiFi描述', trigger: 'blur' }
                    ],
                    longitude: [
                        { required: true, message: '输入商城经度', trigger: 'blur' }
                    ],
                    dimensionality: [
                        { required: true, message: '输入商城纬度', trigger: 'blur' }
                    ],
                },
                //规格
                inputVisible: false,
                inputValue: '',
                loading:false, //加载中
            }
        },
        created(){
            this.getData();
        },
        computed: {
            data() {
                return this.tableData.filter((d) => {
                    let is_del = false;
                    for (let i = 0; i < this.del_list.length; i++) {
                        if (d.title === this.del_list[i].title) {
                            is_del = true;
                            break;
                        }
                    }
                    return d;
             
                })
            }
        },
        methods: {
            // 分页导航
            handleCurrentChange(val) {
                this.cur_page = val;
            },
            // 获取 easy-mock 的模拟数据
            getData() {
                //不传参数要写null
                var params=this.$qs.stringify({
                    select_word: this.select_word,
                    pageIndex: this.cur_page,
                    number: this.number
                });
             
                this.$api.post('ShopWifi/getWifiList', params, res => {
                    this.tableData = res.data.list;
                    this.sumPage = res.data.sumPage*10;
                    this.cur_page = res.data.currentPage;
                  
                }, err => {
                    this.tableData = [];
                    this.sumPage = 1*10;
                    this.cur_page = 1;
                    this.$message.error(err.msg);
                });
            },
            search() {
                this.is_search = true;
                this.getData();
            },
           
        
            handleEdit(index, row, status) {
                this.AddOrSave=status;
				   if(status==1){
					this.form = {
						id: null,
						position_name: null,
						ad_width: null,
						ad_height: null,
						description: null
					};
				   }
                if(index!=undefined && row!=undefined){
                    this.idx = index;
                    const item = this.tableData[index];
                    this.form = {
                      id: item.id,
                        title: item.title,
                        password: item.password,
                        content: item.content,
                        longitude: item.longitude,
                        dimensionality: item.dimensionality,
                    };
                }
                this.editVisible = true;
            },
            handleDelete(index, row) {
                this.idx = index;
                this.form = row;
                this.delVisible = true;
            },
            delAll() {
                const length = this.multipleSelection.length;
                let str = '';
                this.del_list = this.del_list.concat(this.multipleSelection);
                for (let i = 0; i < length; i++) {
                    str += this.multipleSelection[i].b_title + ' ';
                }
          
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 保存编辑
            saveEdit(formName) {
                // this.$set(this.tableData, this.idx, this.form);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.editVisible = false;
                        var params=null;
                        //1表示添加，2表示更新
                        if(this.AddOrSave==1){
                            params=this.$qs.stringify({
                                title: this.form.title,
                                password: this.form.password,
                                content: this.form.content,
                                longitude: this.form.longitude,
                                dimensionality: this.form.dimensionality,
                            });
                        }else{
                            params=this.$qs.stringify({
                                id: this.form.id,
                                title: this.form.title,
                                password: this.form.password,
                                content: this.form.content,
                                longitude: this.form.longitude,
                                dimensionality: this.form.dimensionality,
                            });
                        }
                
                        this.$api.post('ShopWifi/saveWifi', params, res => {
                            this.getData();
                            this.$message.success(res.msg);
                        }, err => {
                            this.$message.error(err.msg);
                        });
                    }else{
                      
                        return false;
                    }
                });
              
            },
            // 确定删除
            deleteRow(){
                var params=this.$qs.stringify({
                    id: this.form.id
                });
            
                this.$api.post('ShopWifi/deleteWifi', params, res => {
                    this.getData();
                    this.$message.success(res.msg+res.data+"条数据");
                }, err => {
                    this.$message.error(err.msg);
                });
                this.delVisible = false;
            },
            //将转移符号替换为html
            escapeStringHTML(str) {
                if(str){
                    str = str.replace(/&lt;/g,'<');
                    str = str.replace(/&gt;/g,'>');
                    str = str.replace(/&quot;/g,'"');
                }
                return str;
            },
            submit(){
                let str=this.escapeStringHTML(this.form.details);
            },
        }
    }

</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }
    .del-dialog-cnt{
        font-size: 16px;
        text-align: center
    }
    .table{
        width: 100%;
        font-size: 14px;
    }
    .red{
        color: #ff0000;
    }
    .mr10{
        margin-right: 10px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        /*height: 100%;*/
        display: block;
    }
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    .button-new-tag {
        margin-left: 10px;
        height: 30px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 110px;
        margin-left: 10px;
        vertical-align: bottom;
    }
    .ql-snow .ql-editor img{
        vertical-align: top !important;
    }
</style>